import { createSlice } from "@reduxjs/toolkit";
// import productData from "../productData";
// import amazingHealth from "../amazingHealth";
// import essentialOils from "../essentialOils";
// import naturalOil from "../naturalOil";
// import herbsProduct from "../herbsProduct";
// import healthProduct from "../healthProduct";
// import homeBestSelling from "../homeBestSelling";
import healthProductMain from "../healthProductMain";
import IslamicProduct from "../IslamicProduct";
import WallArtInner from "../WallArtInner";
import IslamicProductInner from "../IslamicProductInner";
import WallArt from "../WallArt";
// import hearbsMain from "../hearbsMain";
// import essentailOilsMain from "../essentailOilsMain";
// import naturalOilMain from "../naturalOilMain";
// import customRecomendation from "../customRecomendation";
// import topRated from "../topRated";
// import medicineProduct from "../medicineProduct";
// import medicineProductMain from "../medicineProductMain";
const initialState = {
  cart: JSON.parse(localStorage.getItem("cart")) || [],
  // productItems: productData,
  // amazingHealthItems: amazingHealth,
  // essentialOilsItems:essentialOils,
  // naturalOilItems:naturalOil,
  // herbsProductItems:herbsProduct,
  // healthProductItems:healthProduct,
  // homeBestSellingItems:homeBestSelling,
  WallArtInnerItems:WallArtInner,
  WallArtItems:WallArt,
  IslamicProductItems:IslamicProduct,
  healthProductMainItems:healthProductMain,
  IslamicProductInnerItems:IslamicProductInner,
  // hearbsMainItems:hearbsMain,
  // essentailOilsMainItems:essentailOilsMain,
  // naturalOilMainItems:naturalOilMain,
  // customRecomendationItems:customRecomendation,
  // topRatedItems:topRated,
  // medicineProductItems:medicineProduct,
  // medicineProductMainItems:medicineProductMain,
  totalQuantity: 0,
  totalPrice: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { id } = action.payload;
      const existingItem = state.cart.find(item => item.id === id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.cart.push({ ...action.payload, quantity: 1 });
      }
      // Update localStorage
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },

    getCartTotal: (state) => {
      let { totalQuantity, totalPrice } = state.cart.reduce(
        (cartTotal, cartItem) => {
          const { price, quantity } = cartItem;
          const itemTotal = price * quantity;
          cartTotal.totalPrice += itemTotal;
          cartTotal.totalQuantity += quantity;
          return cartTotal;
        },
        {
          totalPrice: 0,
          totalQuantity: 0,
        }
      );
      state.totalPrice = parseFloat(totalPrice.toFixed(2));
      state.totalQuantity = totalQuantity;
    },

    removeItem: (state, action) => {
      state.cart = state.cart.filter((item) => item.id !== action.payload);
      // Update localStorage
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },

    increaseItemQuantity: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      // Update localStorage
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },

    decreaseItemQuantity: (state, action) => {
      state.cart = state.cart.map((item) => {
        if (item.id === action.payload) {
          // Decrease the quantity by 1
          const updatedQuantity = item.quantity - 1;

          // Check if the updated quantity is 0, then remove the item
          if (updatedQuantity === 0) {
            return null;
          }

          // Return the updated item with the decreased quantity
          return { ...item, quantity: updatedQuantity };
        }

        // If the item's ID doesn't match the action payload, return it unchanged
        return item;
      });

      // Remove items with quantity 0 from the cart
      state.cart = state.cart.filter((item) => item !== null);
      // Update localStorage
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
  },
});

export const { addToCart, getCartTotal, removeItem, increaseItemQuantity, decreaseItemQuantity } = cartSlice.actions;
export default cartSlice.reducer;

