import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "./Banner.css"
export default function Banner() {
    return (
        <div className="banner-container">
        <Carousel className="vertical" interval={2000} controls={false} indicators={false}>
            <Carousel.Item>
                <img src="/assets/Banner/first.jpg" alt="" className="carousel-img" />
                <Carousel.Caption className="carousel-caption-custom">
                    <div className="text">
                        <h3 className="transparent-text">Hot Trend Now</h3>
                    </div>
                    <div className="img">
                        <img src="/assets/Banner/1.png" alt="" />
                    </div>
                    <div className="content">
                        <h1>Inspire Your Living Space</h1>
                        <p>"Every room needs a touch of black just as it needs one mtique piece"</p>
                        <button>Shop Now</button>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src="/assets/Banner/BG 2nd Cover.jpg" alt="" className="carousel-img" />
                <Carousel.Caption className="carousel-caption-custom">
                    <div className="text">
                        <h3 className="transparent-text">UNIQUE STYLE</h3>
                    </div>
                   
                    <div className="content">
                        <h1>Decorate to impress</h1>
                        <p>"Every room needs a touch of black just as it needs one mtique piece"</p>
                        <button>Shop Now</button>
                    </div>
                    <div className="img">
                        <img src="/assets/Banner/2nd-Slide-Images.png" alt="" />
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src="/assets/Banner/second.jpg" alt="" className="carousel-img"  style={{width:'100%',height:"800px"}}  />
            </Carousel.Item>
        </Carousel>
    </div>
    )
}
