export default [
  {
    id: 5,
        title_one: "Wallart",
        title: "3D Round Wall Art. ",
        pricedel:'₨ 3000',
        price: 1200,
        imgpx: require("./assets/250 x 250/16.jpg"),
        img: require("./assets/3D Round Wall Art/card.jpg"),
        img2: require("./assets/3D Round Wall Art/1.jpg"),
        img3: require("./assets/3D Round Wall Art/2.jpg"),
        img4: require("./assets/3D Round Wall Art/3.jpg"),
        Format1:" Format:",
        Format:" Wall Hanging",
        Material1:"Material:",
        Material:"Wooden Ply",
        Color1:'Color:',
        Color:'Black',
        // ProductDimensions1:"Product Dimensions:",
        // ProductDimensions:"- 16 W x 17.2 H",
        Brand1:"Brand:",
        Brand:"Art Stylo",
        Category1:"Category:",
        Category:"Wallart",
        SKU1:"SKU:",
        SKU:'AS-WA0013',
        // quantity: 1,
        // ml:'200 ML',
        // ml:'200 ML',
      },
     
      {
        id: 6,
        title_one: "Wallart",
        title: "Round Color Tree Wall Art. ",
        pricedel:'₨ 3000',
        price: 1800,
        imgpx: require("./assets/250 x 250/15.jpg"),
        img: require("./assets/Round Color Tree Wall Art/card.jpg"),
        img2: require("./assets/Round Color Tree Wall Art/1.jpg"),
        img3: require("./assets/Round Color Tree Wall Art/2.jpg"),
        img4: require("./assets/Round Color Tree Wall Art/3.jpg"),
        img5: require("./assets/Round Color Tree Wall Art/4.jpg"),
        Format1:" Format:",
        Format:" Wall Hanging",
        Material1:"Material:",
        Material:"Wooden Ply",
        Color1:'Color:',
        Color:'Handart Paint',
        // ProductDimensions1:"Product Dimensions:",
        // ProductDimensions:"- 16 W x 17.2 H",
        Brand1:"Brand:",
        Brand:"Art Stylo",
        Category1:"Category:",
        Category:"Wallart",
        SKU1:"SKU:",
        SKU:'AS-WA0012',
        // quantity: 1,
        // ml:'200 ML',
        // ml:'200 ML',
      },
      {
        id: 7,
        title_one: "Wallart",
        title: "3D Triangle Wall Art.",
        pricedel:'₨ 3000',
        price: 1800,
        imgpx: require("./assets/250 x 250/14.jpg"),
        img: require("./assets/3D Triangle Wall Art/card.jpg"),
        img2: require("./assets/3D Triangle Wall Art/1.jpg"),
        img3: require("./assets/3D Triangle Wall Art/2.jpg"),
        img4: require("./assets/3D Triangle Wall Art/3.jpg"),
        img5: require("./assets/3D Triangle Wall Art/4.jpg"),
        Format1:" Format:",
        Format:" Wall Hanging",
        Material1:"Material:",
        Material:"Wooden Ply",
        Color1:'Color:',
        Color:'Black',
        // ProductDimensions1:"Product Dimensions:",
        // ProductDimensions:"- 16 W x 17.2 H",
        Brand1:"Brand:",
        Brand:"Art Stylo",
        Category1:"Category:",
        Category:"Wallart",
        SKU1:"SKU:",
        SKU:'AS-WA0011',
        // quantity: 1,
        // ml:'200 ML',
        // ml:'200 ML',
      },
      ];
