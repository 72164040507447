export default [
  {
    id: 2,
        title_one: "Wallart",
        title: "Round Candles Wall Art. ",
        pricedel:'₨ 3000',
        price: 1500,
        imgpx: require("./assets/250 x 250/cardcandles.jpg"),
        img: require("./assets/Round Candles Wall Art/cardcandles.jpg"),
        img2: require("./assets/Round Candles Wall Art/1.jpg"),
        img3: require("./assets/Round Candles Wall Art/2.jpg"),
        img4: require("./assets/Round Candles Wall Art/3.jpg"),
        img5: require("./assets/Round Candles Wall Art/4.jpg"),
        Format1:" Format:",
        Format:" Wall Hanging",
        Material1:"Material:",
        Material:"Wooden Ply",
        Color1:'Color:',
        Color:'Handart Paint',
        // ProductDimensions1:"Product Dimensions:",
        // ProductDimensions:"- 16 W x 17.2 H",
        Brand1:"Brand:",
        Brand:"Art Stylo",
        Category1:"Category:",
        Category:"Wallart",
        SKU1:"SKU:",
        SKU:'AS-WA0007',
        // quantity: 1,
        // ml:'200 ML',
        // ml:'200 ML',
      },
     
      {
        id: 3,
        title_one: "Wallart",
        title: "Home Sweet Home Wall Art. ",
        pricedel:'₨ 3000',
        price: 1000,
        imgpx: require("./assets/250 x 250/5.jpg"),
        img: require("./assets/Home Sweet Home Wall Art/card.jpg"),
        img2: require("./assets/Home Sweet Home Wall Art/1.jpg"),
        img3: require("./assets/Home Sweet Home Wall Art/2.jpg"),
        img4: require("./assets/Home Sweet Home Wall Art/3.jpg"),
        img5: require("./assets/Home Sweet Home Wall Art/4.jpg"),
        Format1:" Format:",
        Format:" Wall Hanging",
        Material1:"Material:",
        Material:"Wooden Ply",
        Color1:'Color:',
        Color:'Black',
        // ProductDimensions1:"Product Dimensions:",
        // ProductDimensions:"- 16 W x 17.2 H",
        Brand1:"Brand:",
        Brand:"Art Stylo",
        Category1:"Category:",
        Category:"Wallart",
        SKU1:"SKU:",
        SKU:'AS-WA0006',
        // quantity: 1,
        // ml:'200 ML',
        // ml:'200 ML',
      },
      {
        id: 4,
        title_one: "Wallart",
        title: "Round Black Tree Wall Art.",
        pricedel:'₨ 3000',
        price: 1500,
        imgpx: require("./assets/250 x 250/2.jpg"),
        img: require("./assets/Round Black Tree Wall Art/card.jpg"),
        img2: require("./assets/Round Black Tree Wall Art/1.jpg"),
        img3: require("./assets/Round Black Tree Wall Art/2.jpg"),
        img4: require("./assets/Round Black Tree Wall Art/3.jpg"),
        img5: require("./assets/Round Black Tree Wall Art/4.jpg"),
        Format1:" Format:",
        Format:" Wall Hanging",
        Material1:"Material:",
        Material:"Wooden Ply",
        Color1:'Color:',
        Color:'Black',
        // ProductDimensions1:"Product Dimensions:",
        // ProductDimensions:"- 16 W x 17.2 H",
        Brand1:"Brand:",
        Brand:"Art Stylo",
        Category1:"Category:",
        Category:"Wallart",
        SKU1:"SKU:",
        SKU:'AS-WA0005',
        // quantity: 1,
        // ml:'200 ML',
        // ml:'200 ML',
      },
      ];

