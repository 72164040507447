import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import "./CheckoutForm.css"
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for React Toastify
import TopHeader from '../Headers/TopHeader/TopHeader';
import BottomHeader from '../Headers/BottomHeader/BottomHeader';

function CheckoutForm() {
    const [showMessage, setShowMessage] = useState(false);
    const [shippingCharges, setShippingCharges] = useState(0);
    const { cart, totalPrice } = useSelector((state) => state.allCart);
    const [checkOutForm, setCheckOutForm] = useState({
        Customer_Name: '',
        City: '',
        Delivery_Address: '',
        Phone_Number: '',
        Email_Address: '',
        DOR: '',
        Country: '',
        Province: '',
        Total: '',
        Discount_percent: '',
        Discount_Amount: '',
        Net_Total: '',
    });

    const [formValid, setFormValid] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'City') {
            setShippingCharges(value === 'Karachi' ? 180 : 250);
        }

        setCheckOutForm({
            ...checkOutForm,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const total = totalPrice;
        const discountAmount = total * 0.10; // Calculate 10% discount
        const shippingCharges = checkOutForm.City === 'Karachi' ? 180 : 250;
        const netTotal = total - discountAmount + shippingCharges;

        if (cart.length > 0) {
            const items = cart.map(item => ({
                Sku: item.SKU,
                Qty: item.quantity,
                Unit_Price: item.price,
                Item_Total: item.quantity * item.price,
            }));

            const formData = {
                ...checkOutForm,
                Total: total,
                Discount_percent: '10%',
                Discount_Amount: discountAmount,
                Net_Total: netTotal,
            };

            const sessionId = 'uniqueSessionId'; // Generate a unique session ID if necessary

            axios
            .post('http://localhost:8081/CartPage', { sessionId, items, formData })
            .then((res) => {
                console.log(res);
                toast.success('Your order has been successfully confirmed. Our representative will call you shortly. Thank you for your order.', {
                    autoClose: 5000, // Optional: Auto close the toast after 5 seconds
                    closeOnClick: true, // Optional: Close the toast when clicked
                    hideProgressBar: false, // Optional: Show/hide progress bar
                    draggable: true // Optional: Allow the toast to be draggable
                });
                axios
                    .post('http://localhost:8081/CheckoutForm', { sessionId, ...formData })
                    .then((res) => {
                        console.log(res);
                        navigate('/');
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error('Error submitting the form. Please try again.');
                    });
            })
            .catch((err) => {
                console.log(err);
                toast.error('Error submitting the form. Please try again.');
            });
        
        }
    };

    useEffect(() => {
        const isFormValid =
            checkOutForm.Customer_Name &&
            checkOutForm.City &&
            checkOutForm.Delivery_Address &&
            checkOutForm.Phone_Number &&
            checkOutForm.DOR &&
            checkOutForm.Country &&
            checkOutForm.Province;

        setFormValid(isFormValid);
    }, [checkOutForm]);

    useEffect(() => {
        const total = totalPrice;
        const discountAmount = total * 0.10;
        const netTotal = total - discountAmount + shippingCharges;

        setCheckOutForm((prevState) => ({
            ...prevState,
            Total: total,
            Discount_Amount: discountAmount,
            Net_Total: netTotal,
        }));
    }, [totalPrice, shippingCharges]);

    // const subTotal = totalPrice;
    const discount = totalPrice * 0.10; // Calculate 10% discount
    const total = totalPrice;
    // const netTotal = totalPrice + shippingCharges
    const netTotal = totalPrice - discount + shippingCharges;

    return (
        <>
            <TopHeader />
            <BottomHeader />

            <div className='d-none'>
                <table>
                    <thead>
                        <th>SKU</th>
                        <th>quantity</th>
                        <th>price</th>
                        <th>SKU</th>
                    </thead>
                    <tbody>
                        {cart?.map((item) => (
                            <tr className="cart_item" key={item.SKU}>
                                <td>{item.SKU}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price}</td>
                                <td>{item.quantity * item.price}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            <div className='checkoutform'>
                <div className="container">
                    <div className="heading_check">
                        <h1>Checkout</h1>
                    </div>
                    <div className="main_form_check">
                        <div className="container">
                            <div className="heading_">
                                <h3>
                                    Returning customer? <Link to="">Click here to login</Link> </h3>
                            </div>
                            <div className="heading_">
                                <h3>
                                    Have A Coupon? <Link to="#">Click here to enter your code</Link> </h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="main_billing my-5">
                                    <div className="billing_details">
                                        <div className="heading_details">
                                            <h3>Billing Details</h3>
                                        </div>

                                        <div className="form_detail">
                                            <div className="form_name d-flex">
                                                <div className="name">
                                                    <Form.Label>Full Name<span>*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="Name"
                                                        name="Customer_Name"
                                                        value={checkOutForm.Customer_Name}
                                                        onChange={handleChange} required
                                                    />
                                                </div>

                                            </div>




                                            <Form.Label className='my-3'>Delivery Address   <span>*</span></Form.Label>
                                            <Form.Control type="text" placeholder="House Number and street name"
                                                name='Delivery_Address'
                                                value={checkOutForm.Delivery_Address}
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Label className='my-3'>Country  <span>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" placeholder='Select An Option'
                                                name='Country'
                                                value={checkOutForm.Country}
                                                onChange={handleChange}
                                            >
                                                <option></option>
                                                <option value="Azad Kashmir">Pakistan</option>
                                                <option value="Balochistan">India</option>


                                            </Form.Select >
                                            <div className="row">
                                                <div className="col-6">
                                                    <Form.Label className='my-3'>Province / State<span>*</span></Form.Label>
                                                    <Form.Select aria-label="Default select example" placeholder='Select An Option'
                                                        name='Province'
                                                        value={checkOutForm.Province}
                                                        onChange={handleChange}
                                                    >
                                                        <option></option>
                                                        <option value="Azad Kashmir">Azad Kashmir</option>
                                                        <option value="Balochistan">Balochistan</option>
                                                        <option value="FATA">FATA</option>
                                                        <option value="Gilgit Baltistan">Gilgit Baltistan</option>
                                                        <option value="Islamabad Capital Territory">Islamabad Capital Territory</option>
                                                        <option value="Khyber Pakhtunkhwa">Khyber Pakhtunkhwa</option>
                                                        <option value="Punjab">Punjab</option>
                                                        <option value="Sindh">Sindh</option>

                                                    </Form.Select>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Label className='my-3'>City  <span>*</span></Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        placeholder=''
                                                        name='City'
                                                        value={checkOutForm.City}
                                                        onChange={handleChange}
                                                    >
                                                        <option></option>
                                                        <option value="Karachi">Karachi</option>
                                                        <option value="Lahore">Lahore</option>
                                                        {/* Add more cities as needed */}
                                                    </Form.Select>
                                                </div>
                                            </div>



                                            <Form.Label className='my-3' >Phone Number<span>*</span></Form.Label>
                                            <Form.Control type="text" placeholder="Phone Number"
                                                name='Phone_Number'
                                                value={checkOutForm.Phone_Number}
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Label className='my-3' >Order Registration<span>*</span></Form.Label>
                                            <Form.Control type="date" placeholder="Phone Number"
                                                name='DOR'
                                                value={checkOutForm.DOR}
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Label className='my-3'    >Email Address (optional)<span>*</span></Form.Label>
                                            <Form.Control type="text" placeholder=" Email"
                                                name='Email_Address'
                                                value={checkOutForm.Email_Address}
                                                onChange={handleChange}
                                            />

                                            <Form.Group className="mb-3 my-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Order Notes (optional)</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder='Notes about your order, eg,special notes for delivery'
                                                // name='Order_Notes'
                                                // value={checkOutForm.Order_Notes}
                                                // onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="Your_Order w-100">
                                            <div className="heading_details">

                                                <h3 className='my-5'>Payment Method</h3>
                                                {/* <div className="main-transfer main-transfer_1">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name='paymentMethod'
                                                    value='Payment_Bank'
                                                    checked={checkOutForm.paymentMethod === 'Payment_Bank'}
                                                    onChange={handleChange}
                                                />&nbsp;&nbsp;
                                                Direct Bank Transfer <br />
                                                Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.
                                            </label>
                                        </div> */}

                                                <div className="main-transfer main-transfer_2 my-3" style={{ borderTop: '1px solid #ddd' }}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name='paymentMethod'
                                                            value='Payment_Status'
                                                            onChange={handleChange}
                                                            required
                                                            checked
                                                        /> &nbsp;&nbsp;
                                                        Cash on delivery
                                                    </label>
                                                </div>

                                            </div>



                                        </div>
                                        <button type="submit" disabled={!formValid}>Place Order</button>

                                        {/* <div className="button_ my-5">
                                            <button type="submit" onClick={openPlaceOrderModal}>Place Order</button>
                                        </div>
                                        <PlaceOrderModal
                                            isOpen={placeOrderModalIsOpen}
                                            onClose={closePlaceOrderModal}
                                            onConfirm={handlePlaceOrder}
                                        /> */}

                                    </div>
                                    <div className="Your_Order ">
                                        <div className="heading_details">
                                            <h3>Your Order</h3>
                                            <div className="Your_Order_deatail">

                                                <table class="shop_table woocommerce-checkout-review-order-table">
                                                    <thead>
                                                        <tr>
                                                            <th class="product-name">Product</th>
                                                            <th class="product-total">Subtotal</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {cart?.map((Payment) => (
                                                            <tr class="cart_item">
                                                                <td style={{ background: '#fff' }} class="product-name">
                                                                    <img width="55" height="55" src={Payment.img} alt="" style={{ width: 'auto', height: '50px' }} /> &nbsp; &nbsp; {Payment.title}&nbsp;														</td>
                                                                <td style={{ background: '#fff' }}>
                                                                    {Payment.quantity}
                                                                </td>


                                                            </tr>
                                                        ))}
                                                        <tr class="order-total" style={{
                                                            background: 'none', borderRight: ' none !important',
                                                            border: '1px solid #dddd'
                                                        }}>
                                                            <th style={{ border: '1px solid #ddd' }}>Total</th>
                                                            <td><strong><span class="woocommerce-Price-amount amount"><bdi>
                                                                <span class="woocommerce-Price-currencySymbol"
                                                                    name="Total"

                                                                >₨</span>&nbsp;{total}</bdi></span></strong>
                                                                {/* <Form.Group controlId="Total">
                                                                   
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="Total"
                                                                        value={checkOutForm.Total}
                                                                        onChange={handleChange}
                                                                        disabled
                                                                        style={{ border: 'none' }}
                                                                    />
                                                                </Form.Group> */}
                                                            </td>
                                                        </tr>
                                                        <tr class="order-total" style={{
                                                            background: 'none', borderRight: ' none !important',
                                                            border: '1px solid #dddd'
                                                        }}>
                                                            <th style={{ border: '1px solid #ddd' }}>Discount</th>
                                                            <td><strong><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">₨</span>&nbsp;{discount}</bdi></span></strong> </td>
                                                        </tr>



                                                        {shippingCharges > 0 && (
                                                            <tr class="order-total" style={{
                                                                background: 'none', borderRight: ' none !important',
                                                                border: '1px solid #dddd'
                                                            }}>
                                                                <th style={{ border: '1px solid #ddd' }}>Shipping Charges</th>
                                                                <td><strong><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol"></span>&nbsp;RS {shippingCharges}</bdi></span></strong> </td>
                                                            </tr>


                                                        )}
                                                        <tr class="order-total" style={{
                                                            background: 'none', borderRight: ' none !important',
                                                            border: '1px solid #dddd'
                                                        }}>
                                                            <th style={{ border: '1px solid #ddd' }}>Net Total</th>
                                                            <td><strong><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">₨</span>&nbsp;{netTotal}</bdi></span></strong> </td>
                                                        </tr>
                                                    </tbody>

                                                </table>

                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default CheckoutForm
