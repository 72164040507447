import React from 'react'
import "./FollowUs.css"
export default function FollowUs() {
    return (
        <div className='FollowUs'>
            <div className="container-fluid">
                <div className="main">
                    <div className="socials">
                        <h1>Follow us on</h1>
                        <ul>
                            <li><a href="https://www.facebook.com/artstylodecor" target='_blank'>
                            <i class="fa-brands fa-facebook-f"></i>
                                </a></li>
                                <li><a href="https://instagram.com/artstylodecor" target='_blank'> 
                            <i class="fa-brands fa-instagram"></i>
                                </a></li>  
                            <li><a href="https://www.tiktok.com/@artstylodecor" target='_blank'>
                            <i class="fa-brands fa-tiktok"></i>
                                </a></li>
                                  
                        </ul>
                    </div>
                    <div className="inputs_main">
                      
                        <div className="inputs">
                            <input type="text" placeholder='Enter your email address...
'/>
                            <button>SUBSCRIBE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
