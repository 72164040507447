import React from 'react'
import "./PopularCollectionoff.css"

export default function PopularCollectionoff() {
    return (
        <div className='PopularCollectionoff'>
            <div className="container-fluid">
              
                <div className="main_">
                <div className="striped_wool1 ">
                <h1>Classic Edition</h1>
                <h3>
                Art Stylo</h3>
                   
                     
                     <div className="btn1">
                        <button>Shop Now</button>
                     </div>
                </div>
                <div className="striped_wool2">
              
                <h1>Exclusive Edition</h1>
                <h3>
                Art Stylo</h3>
                    
                     <div className="btn1">
                        <button>Shop Now</button>
                     </div>
                </div>
                </div>
            </div>
        </div>
    )
}
