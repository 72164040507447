import React from 'react'
import Product from '../Products/Product'
import TopHeader from '../../Headers/TopHeader/TopHeader'
import BottomHeader from '../../Headers/BottomHeader/BottomHeader'
import Footer from '../../Footer/Footer'

export default function Shop() {
    return (
        <div>
          <TopHeader/>
          <BottomHeader/>
          <Product/>
          <Footer/>
        </div>
    )
}
