import React from 'react'
import "./Shipping.css"
export default function Shipping() {
    return (
        <div className='homeshipping'>
            <div className="container">
                <div className="main">
                    <div className="unique">
                        <ul>
                            <li>
                            <img src="/assets/unique.PNG" alt="" />
                            </li>
                            <li>
                                <span>Unique Design</span>
                            </li>
                        </ul>
                    </div>
                    <div className="unique">
                        <ul>
                            <li>
                            <img src="/assets/shipping.PNG" alt="" />
                            </li>
                            <li>
                                <span>Shipping</span>
                            </li>
                        </ul>
                    </div>
                    <div className="unique">
                        <ul>
                            <li>
                            <img src="/assets/promotion code.PNG" alt="" />
                            </li>
                            <li>
                                <span>Promotion Code</span>
                            </li>
                        </ul>
                    </div>
                    <div className="unique">
                        <ul>
                            <li>
                            <img src="/assets/fast support.PNG" alt="" />
                            </li>
                            <li>
                                <span>Fast Support</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
