import React from 'react'
import ContactForm from '../ContactForm/ContactForm'
import TopHeader from '../../../Headers/TopHeader/TopHeader'
import BottomHeader from '../../../Headers/BottomHeader/BottomHeader'
import Footer from '../../../Footer/Footer'
function Contact() {
    return (
        <div>
            <TopHeader />
            <BottomHeader />
            <ContactForm />
            <Footer />
        </div>
    )
}

export default Contact

