import React from 'react'
import Card from 'react-bootstrap/Card';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "./Insatgramproducts.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from 'react-redux';
export default function Insatgramproducts() {



    return (
        <div className='Insatgramproducts'>
            <div className="container-fluid">
                <div className="main_slide">
                    <div className="content">
                        <h1>@Artstylodecor
                        </h1>
                        <p>Give your home such a deceptive look <br /> that everyone has  to believe that <br />  you belong to the royal family.

                        </p>
                    </div>
                    <div className="slider">

                        <div className="d-flex gap-5  cards">
                            <div className="img">
                                <img src="./assets/slider1.jpg" alt="" />
                            </div>
                            <div className="img2">
                                <img src="./assets/slider2.jpg" alt="" />
                            </div>
                            {/* <div className="img3">
                                <img src="./assets/slider3.jpg" alt="" />
                            </div> */}
                            
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}
