import React from 'react'
import "./Product.css";
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from "../../../features/cartSlice";
export default function Product() {

    const healthProductMainItems = useSelector((state) => state.allCart.healthProductMainItems);
    const WallArtItems = useSelector((state) => state.allCart.WallArtItems);
    const IslamicProductItems = useSelector((state) => state.allCart.IslamicProductItems);
    const IslamicProductInnerItems = useSelector((state) => state.allCart.IslamicProductInnerItems);
    const WallArtInnerItems = useSelector((state) => state.allCart.WallArtInnerItems);
    const dispatch = useDispatch();
    return (
        <div className='Shop_Products'>
            <div className="conatiner-fluid my-5">
                <h1>Products</h1>
                <h3 className='navigate'> <Link to="/"> Home</Link> {">"}<Link to="/Shop"> Shop</Link></h3>
                <div className="row">
                    {healthProductMainItems.map((item) => (
                        <div className="col-3">
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={item.img} />
                                <Card.Body>
                                    <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                                    <Card.Text>
                                        {item.title}
                                    </Card.Text>
                                    <Card.Text>
                                        <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                                    </Card.Text>

                                    <Card.Text>
                                        <div class="contain">
                                            <div class="animated-bg">
                                                <div className="links">
                                                    <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                                        <i className="fa-solid fa-cart-shopping" ></i>
                                                    </Link>
                                                    <Link to={`/SingleProductDetailstow/${item.id}`} >
                                                        <i className="fa-solid fa-eye"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Text>

                                    <Card.Text>
                                        <Link to="#">
                                            <i class="fa-regular fa-heart"></i>
                                        </Link>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                    {IslamicProductItems.map((item) => (
                        <div className="col-3">
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={item.img} />
                                <Card.Body>
                                    <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                                    <Card.Text>
                                        {item.title}
                                    </Card.Text>
                                    <Card.Text>
                                        <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                                    </Card.Text>

                                    <Card.Text>
                                        <div class="contain">
                                            <div class="animated-bg">
                                                <div className="links">
                                                    <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                                        <i className="fa-solid fa-cart-shopping" ></i>
                                                    </Link>
                                                    <Link to={`/SingleProductDetailsIslam/${item.id}`} >
                                                        <i className="fa-solid fa-eye"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Text>

                                    <Card.Text>
                                        <Link to="#">
                                            <i class="fa-regular fa-heart"></i>
                                        </Link>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                    {WallArtItems.map((item) => (
                        <div className="col-3">
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={item.img} />
                                <Card.Body>
                                    <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                                    <Card.Text>
                                        {item.title}
                                    </Card.Text>
                                    <Card.Text>
                                        <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                                    </Card.Text>

                                    <Card.Text>
                                        <div class="contain">
                                            <div class="animated-bg">
                                                <div className="links">
                                                    <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                                        <i className="fa-solid fa-cart-shopping" ></i>
                                                    </Link>
                                                    <Link to={`/SingleProductDetailsIWallArt/${item.id}`} >
                                                        <i className="fa-solid fa-eye"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Text>

                                    <Card.Text>
                                        <Link to="#">
                                            <i class="fa-regular fa-heart"></i>
                                        </Link>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                    {WallArtInnerItems.map((item) => (
                        <div className="col-3">
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={item.img} />
                                <Card.Body>
                                    <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                                    <Card.Text>
                                        {item.title}
                                    </Card.Text>
                                    <Card.Text>
                                        <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                                    </Card.Text>

                                    <Card.Text>
                                        <div class="contain">
                                            <div class="animated-bg">
                                                <div className="links">
                                                    <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                                        <i className="fa-solid fa-cart-shopping" ></i>
                                                    </Link>
                                                    <Link to={`/SingleProductDetailsIWallArtInner/${item.id}`} >
                                                        <i className="fa-solid fa-eye"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Text>

                                    <Card.Text>
                                        <Link to="#">
                                            <i class="fa-regular fa-heart"></i>
                                        </Link>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                    {IslamicProductInnerItems.map((item) => (
                        <div className="col-3">
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={item.img} />
                                <Card.Body>
                                    <Card.Title className='mt-4'>{item.title_one}</Card.Title>
                                    <Card.Text>
                                        {item.title}
                                    </Card.Text>
                                    <Card.Text>
                                        <del>{item.pricedel}</del> <span>₨ {item.price}</span>
                                    </Card.Text>

                                    <Card.Text>
                                        <div class="contain">
                                            <div class="animated-bg">
                                                <div className="links">
                                                    <Link to="#" onClick={() => dispatch(addToCart(item))}>
                                                        <i className="fa-solid fa-cart-shopping" ></i>
                                                    </Link>
                                                    <Link to={`/SingleProductDetailsIslamInner/${item.id}`} >
                                                        <i className="fa-solid fa-eye"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Text>

                                    <Card.Text>
                                        <Link to="#">
                                            <i class="fa-regular fa-heart"></i>
                                        </Link>
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                </div>


            </div>
        </div>
    )
}
