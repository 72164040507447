"use client"
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
export default function Login() {
  const navigate = useNavigate();
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    
    axios.post('http://localhost:8081/Login', { Email, Password })
      .then((res) => {
        console.log(res);
        if (res.data === "Success") {
          navigate('/Orders');
          toast.success('Login successfully!', { autoClose: 4000, position: 'last' }); // Display a success toast message with shorter duration and at the 
        } else {
          toast.error('User not found', { autoClose: 4000, position: 'left' });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('An error occurred', { autoClose: 4000, position: 'left' });
      });
};
  return (
    <>
      <main className='login'>
        <div className="container">
          <form className="form" onSubmit={handleSubmit}  >
            <h1 className="title">Log In</h1>
            <div className="inputGroup">

              <input type="email" id="email" className="input"
                required
                placeholder='Enter your User Name'
                name="Email"
                onChange={(e) => setEmail(e.target.value)}
             
                
              />
            </div>
            <div className="inputGroup">

              <input type="password" id="password" className="input"
                placeholder='Enter your User Password'
                required
                name="Password"
                onChange={(e) => setPassword(e.target.value)}
               
              />
            </div>
          
              <div className="bttn1">
                <button type="submit" className="button">Log In</button>
              </div>
       
            {/* <p className="message">
              Dont have an account?
              <Link to="/signup">
                <p className="link">Sign Up</p>
              </Link>
            </p> */}
          </form>
        </div>
      </main>
    </>
  )
}
