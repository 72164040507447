import "./BottomHeader.css";
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCartTotal } from "../../../features/cartSlice";

export default function BottomHeader() {
    const { cart, totalQuantity } = useSelector((state) => state.allCart);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCartTotal());
    }, [dispatch, cart]); // Include dispatch and cart in the dependency array

    useEffect(() => {
        const links = document.querySelectorAll('.pages a');
        links.forEach(link => {
            if (link.href === window.location.href) {
                link.classList.add('active');
            }
        });
    }, []);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        <div className={`container_fluid ${isFixed ? 'fixed' : ''}`}>
            <div className="bottom_header" >
                <div className="logo">
                    <img src="/assets/logo.png" alt="Logo" />
                </div>
                <div className="pages">
                    <ul>
                        <li>
                            <Link to="/" className="link">Home</Link>
                        </li>
                        <li>
                            <Link to="/Shop" className="link">Shop</Link>
                        </li>
                        <li>
                            <Link to="/Contact" className="link">Contact Us</Link>
                        </li>
                    </ul>
                </div>
                <div className="search">
                    <ul>
                        <li>
                            <div className="search_input">
                                <input type="text" placeholder="Search products..." />
                                <i className="fa-solid fa-magnifying-glass glass"></i>
                            </div>
                        </li>
                        <li>
                            <Link to="#">
                                <i className="fa-solid fa-heart"></i> <sup className="sup_1">0</sup>
                            </Link>
                        </li>
                        <li>
                            <Link to="/CartPage">
                                <i className="fa-solid fa-cart-shopping"></i><sup className="sup">{totalQuantity}</sup>
                            </Link>
                        </li>
                        <li> <Link to="/Login"><i class="fa-solid fa-user"></i></Link></li>
                        <li>
                            <Button variant="primary" onClick={handleShow}>
                                <i className="fa-solid fa-bars"></i>
                            </Button>

                            <Offcanvas show={show} onHide={handleClose}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>
                                        <img src="/assets/logo.png" alt="Logo" style={{ width: "auto", height: '100px' }} />
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <div className="pages_res">
                                        <ul>
                                            <li>
                                                <Link to="/" className="link">Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/Shop" className="link">Shop</Link>
                                            </li>
                                            <li>
                                                <Link to="/Contact" className="link">Contact Us</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
