import React from 'react'
import TopHeader from '../../Headers/TopHeader/TopHeader'
import BottomHeader from '../../Headers/BottomHeader/BottomHeader'
import Banner from '../Banner/Banner'
import Shipping from '../Shipping/Shipping'
import ProductsStructure from '../ProductsStructure/ProductsStructure'
import ProductTabs from '../ProductTabs/ProductTabs'
import PopularCollection from '../PopularCollection/PopularCollection'
import HandPicked from '../HandPicked/HandPicked'
import ProductSlideOne from '../ProductSlideOne/ProductSlideOne'
import PopularCollectionoff from '../PopularCollectionoff/PopularCollectionoff'
import Insatgramproducts from '../Insatgramproducts/Insatgramproducts'
import FollowUs from '../FollowUs/FollowUs'
import Footer from '../../Footer/Footer'

export default function Home() {
    return (
        <div>
            <TopHeader/>
            <BottomHeader/>
            <Banner/>
            <Shipping/>
            <ProductsStructure/>
            <ProductTabs/>
            <PopularCollection/>
            <HandPicked/>
            <ProductSlideOne/>
            <PopularCollectionoff/>
            <Insatgramproducts/>
            <FollowUs/>
            <Footer/>
        </div>
    )
}
