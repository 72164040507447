export default [
  {
    id: 1,
    title_one: "Calendar",
    title: "Lifetime Wooden Perpetual Wall Calendar.",
    pricedel: '₨ 3000',
    price: 2000,
    img: require("./assets/products/Card Calender.jpg"),
    imgpx: require("./assets/calender250px.jpg"),
    img2: require("./assets/Calender/1.jpg"),
    img3: require("./assets/Calender/2.jpg"),
    img4: require("./assets/Calender/3.jpg"),
    img5: require("./assets/Calender/4.jpg"),
    Format1: "Format:",
    Format: "Wall Mount Quran Box",
    Material1: "Material:",
    Material: "Wooden Ply",
    Color1: 'Color:',
    Color: 'HandArt Multi Colors',
    ProductDimensions1: "Product Dimensions:",
    ProductDimensions: "- 16 W x 17.2 H",
    Brand1: "Brand:",
    Brand: "- Art Stylo",
    Category1: "Category:",
    Category: "Calendar",
    SKU1: "SKU:",
    SKU: 'AS-CAL0001',
  },
];
