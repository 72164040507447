import React from 'react'
import "./ProductsStructure.css"
export default function ProductsStructure() {
    return (
        <div className='productsstructure'>
            <div className="container-fluid">
             <div className="main_products">
                <div className="productone">
                  <div className="img1">
                    <img src="/assets/ProductStructure3.jpg" alt="" />
                     <div className="btn1">
                        <button>Quran Holder</button>
                     </div>
                  </div>
                  <div className="img1">
                    <img src="/assets/ProductStructure1.jpg" alt="" className='img2'/>
                     <div className="btn1">
                        <button>Multilayer Wallart</button>
                     </div>
                  </div>
                </div>
                <div className="producttwo">
                <div className="productone productone1">
                  <div className="img1 img3">
                    <img src="/assets/ProductStructure5.jpg" alt="" style={{width:"auto",height:"380px"}} />
                     <div className="btn1">
                        <button className='img_3btn'>Home Sweet Home</button>
                     </div>
                  </div>
                  <div className="img1 img3">
                    <img src="/assets/ProductStructure4.jpg" alt="" className='img2' style={{width:"auto",height:"380px"}} />
                     <div className="btn1">
                        <button className='img_3btn'>Round Tree</button>
                     </div>
                  </div>
                </div>
                <div className="productone productone1 productone2" >
                  <div className="img1">
                    <img src="/assets/ProductStructure2.jpg" alt="" className='img2'/>
                     <div className="btn1">
                        <button>3D Wallart</button>
                     </div>
                  </div>
                </div>
                </div>
             </div>
            </div>
        </div>
    )
}
