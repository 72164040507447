export default [
  {
    id: 12,
    title_one: "Islamic",
    title: "Islamic Calligraphy Wall Art 3 Pieces. ",
    price: 2000,
    img: require("./assets/Islamic Calligraphy Wall Art 3 Pieces/card.jpg"),
    img2: require("./assets/Islamic Calligraphy Wall Art 3 Pieces/1.jpg"),
    img3: require("./assets/Islamic Calligraphy Wall Art 3 Pieces/2.jpg"),
    img4: require("./assets/Islamic Calligraphy Wall Art 3 Pieces/3.jpg"),
    img5: require("./assets/Islamic Calligraphy Wall Art 3 Pieces/4.jpg"),
    Format1: " Format:",
    Format: " Islamic Wall Hanging",
    Material1: "Material:",
    Material: "Wooden Ply",
    Color1: 'Color:',
    Color: 'Black with Golden Touch',
    // ProductDimensions1:"Product Dimensions:",
    // ProductDimensions:"- 16 W x 17.2 H",
    Brand1: "Brand:",
    Brand: "- Art Stylo",
    Category1: "Category:",
    Category: "- Islamic",
    SKU1: "SKU:",
    SKU: 'AS-ISL0006',
    // quantity: 1,
    // ml:'200 ML',
  },

];

