import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import TopHeader from '../Headers/TopHeader/TopHeader';
import BottomHeader from '../Headers/BottomHeader/BottomHeader';
import Footer from '../Footer/Footer';

export default function Orders() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        fetch('http://localhost:8081/Orders')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Fetched orders:', data); // Log the data
                setOrders(data);
                setLoading(false); // Set loading state to false
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
                setLoading(false); // Set loading state to false even on error
            });
    }, []);

    if (loading) {
        return (
            <div>
                <TopHeader />
                <BottomHeader />
                <div className="container">
                    <div className="Orders myp-5">
                        <h1 align="center" className='my-5'>Orders</h1>
                        <p>Loading...</p>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <TopHeader />
            <BottomHeader />
            <div className="container">
                <div className="Orders myp-5">
                    <h1 align="center" className='my-5'>Orders</h1>
                    {orders.length === 0 ? (
                        <p>No orders available</p>
                    ) : (
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Customer Name</th>
                                    <th>Delivery Address</th>
                                    <th>Country</th>
                                    <th>Province</th>
                                    <th>City</th>
                                    <th>Phone Number</th>
                                    <th>DOR</th>
                                    <th>Sku</th>
                                    <th>Qty</th>
                                    <th>Unit Price</th>
                                    <th>Item Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map(order => (
                                    <tr key={order.id}>
                                        <td>{order.id}</td>
                                        <td>{order.Customer_Name}</td>
                                        <td>{order.Delivery_Address}</td>
                                        <td>{order.Country}</td>
                                        <td>{order.Province}</td>
                                        <td>{order.City}</td>
                                        <td>{order.Phone_Number}</td>
                                        <td>{order.DOR}</td>
                                        <td>{order.Sku}</td>
                                        <td>{order.Qty}</td>
                                        <td>{order.Unit_Price}</td>
                                        <td>{order.Item_Total}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}
