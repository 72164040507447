import React from 'react'
import "./HandPicked.css"
export default function HandPicked() {
    return (
        <div className='HandPicked'>
            <div className="container-fluid">
                <div className="mainhand">
                     <div className="picked">
                        <div>
                        <img src="/assets/handpicked1.jpg" alt=""  className='img1' />
                        </div>
                        <div>
                        <img src="/assets/handpinked2.jpg" alt=""  className='img2' style={{borderRadius:"100%"}} />
                        </div>
                     </div>
                     <div className="content">
                        <h1>Where design meets innovation</h1>
                        <p>Welcome to ART STYLO, where your dream home decor vision becomes a reality. We understand that your home is more than just a place to live—it's a reflection of your personality and style. Our curated collection of home decor items, from elegant furniture to unique accessories, is designed to inspire and transform your living space. Whether you're looking for a contemporary look, a rustic charm, or a blend of both, our high-quality products cater to every taste and budget. With ART STYLO, creating a cozy, stylish, and inviting home has never been easier. Explore our collection and discover the perfect pieces to elevate your home decor to the next level.</p>
                        <button>Shop Now</button>
                     </div>
                </div>
            </div>
        </div>
    )
}
