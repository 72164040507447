// Components/Preloader/Preloader.js
import React, { useState, useEffect } from 'react';
import './Preloader.css';

const Preloader = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(interval);
                    return 100;
                }
                return Math.min(oldProgress + 1, 100);
            });
        }, 20); // Adjust the speed of the progress bar

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="preloader">
            <img src="/assets/logo.png" alt="Logo" className="logo" />
            <div className="progress-container">
                <div className="progress-bar">
                    <div className="progress" style={{ width: `${progress}%` }}></div>
                </div>
                <div className="progress-number">{progress}%</div>
            </div>
        </div>
    );
};

export default Preloader;

