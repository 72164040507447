// src/Components/NotFound/NotFound.js
import React from 'react';
import "./404NotFount.css"
import { Link } from 'react-router-dom';
const NotFound = () => {
  return (
    <div className="Error">
    <section className="header_banner">
    <div className="banner_content">
      <div className="button">
        <Link to="/">
        <button className="call_now">Back to Home</button>
        </Link>
      </div>
    </div>
  </section>
  </div>
  );
}

export default NotFound;
