import React from 'react'
import "./PopularCollection.css"

export default function PopularCollection() {
    return (
        <div className='PopularCollection'>
            <div className="container-fluid">
                <div className="striped_wool">
                     <h3>Popular Collection</h3>
                     <h1>"Designs as unique as you are"</h1>
                     <div className="btn1">
                        <button>Shop Now</button>
                     </div>
                </div>
                <div className="main_">
                <div className="striped_wool1 ">
                    
                     <h3>Premium Collection</h3>
                     <div className="btn1">
                        <button>Shop Now</button>
                     </div>
                </div>
                <div className="striped_wool2">
                     <h3>Trending 2024</h3>
                     {/* <h1>Vivamus congue eu diam aliquet vestibulum</h1> */}
                     <div className="btn1">
                        <button>Shop Now</button>
                     </div>
                </div>
                </div>
            </div>
        </div>
    )
}
