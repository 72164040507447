"use client"
import { Link } from "react-router-dom"
import "./TopHeader.css"
export default function TopHeader() {
    return (
        <div className="container-fluid  container_fluid">
            <div className="top_header">
                <div className="contacts">
                    <ul>
                        <li>
                            <Link to="#">
                                <i className="fa-solid fa-envelope"></i> <span>info@artstylo.com</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                <i className="fa-solid fa-phone-volume phone"></i><span>+(92)315 2226901</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="tosocailsp_header">
                    <ul>
                        <li>
                            <Link to="#">
                                <i className="fa-brands fa-facebook"></i> <span>facebook</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                <i class="fa-brands fa-instagram"></i> <span>Instagram</span>
                            </Link>
                        </li>
                    </ul>
                </div>
               
                <div className="whatsaap">
            <a href="https://wa.me/923152226901" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-whatsapp" style={{ color: "#25D366" }}></i>
            </a>
        </div>
            </div>
        </div>
    )
}
