// components/SignUp.j
import axios from 'axios';
import { Link, Router, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Signup.css'
import { useState } from 'react';
const SignUp = () => {
  const navigate = useNavigate();
  const  [Signupher, setSignupher] = useState({
    Name: '',
    Email: '',
    Password: '',
 
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupher({
      ...Signupher,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    
    axios
      .post('http://localhost:8081/SignUp', Signupher)
      .then((res) => {
        console.log(res);
        navigate('/Login');
        toast.success('User Register Successfull', { autoClose: 4000, position: 'last' }); // Display a success toast message with shorter duration and at the bottom center
      })
      .catch((err) => console.log(err));
  };
  return (
    <main className='signup'>
    <div className="container">
      <form className="form" onSubmit={handleSubmit}>
        <h1 className="title">Sign Up</h1>
        <div className="inputGroup">
          
          <input type="text" id="name" className="input" 
          required
          name="Name"
          value={Signupher.Name}
          onChange={handleChange}
          placeholder='Name'
          />
        </div>
        <div className="inputGroup">
         
          <input type="email" id="email" className="input"  
            required
            name="Email"
            value={Signupher.Email}
            onChange={handleChange}
            placeholder='Email'
          />
        </div>
        <div className="inputGroup">
     
          <input type="password" id="password" className="input" 
            required
            name="Password"
            value={Signupher.Password}
            onChange={handleChange}
            placeholder='Password'
          />
        </div>
        <div className="bttn1">
        <button type="submit" className="button">Sign Up</button>
        </div>
        <p className="message">
          Already have an account?{' '}
          <Link to="/">
            <p className="link">Log In</p>
          </Link>
        </p>
      </form>
    </div>
    </main>
  );
};

export default SignUp;
