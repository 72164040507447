export default [
  {
    id: 8,
    title_one: "Islamic",
    title: "Wooden Quran Box for Wall with Hanger.",
    price: 2000,
    img: require("./assets/islamicproduct/card quran holder.jpg"),
    imgpx: require("./assets/islamic250px.jpg"),
    img2: require("./assets/islamicproduct/1.jpg"),
    img3: require("./assets/islamicproduct/2.jpg"),
    img4: require("./assets/islamicproduct/3.jpg"),
    img5: require("./assets/islamicproduct/4.jpg"),
    Format1: "Format:",
    Format: "Wall Mount Quran Box",
    Material1: "Material:",
    Material: "Wooden Ply",
    Color1: 'Color:',
    Color: 'Black and Golden',
    Brand1: "Brand:",
    Brand: "- Art Stylo",
    Category1: "Category:",
    Category: "- Islamic",
    SKU1: "SKU:",
    SKU: 'AS-ISL0001',
  },
  {
    id: 9,
    title_one: "Islamic",
    title: "Allah Muhammad Wall Art.",
    price: 1500,
    imgpx: require("./assets/250 x 250/4.jpg"),

    img: require("./assets/cardAllahMuhammad/card.jpg"),
    img2: require("./assets/cardAllahMuhammad/3.jpg"),
    img3: require("./assets/cardAllahMuhammad/CardAllahMuhammad.jpg"),
    img4: require("./assets/cardAllahMuhammad/1.jpg"),
    img5: require("./assets/cardAllahMuhammad/2.jpg"),
    Format1: "Format:",
    Format: "Islamic Wall Decor",
    Material1: "Material:",
    Material: "Wooden Ply",
    Color1: 'Color:',
    Color: 'Black',
    Brand1: "Brand:",
    Brand: "Art Stylo",
    Category1: "Category:",
    Category: "Islamic",
    SKU1: "SKU:",
    SKU: 'AS-ISL0002',
  },
  {
    id: 10,
    title_one: "Islamic",
    title: "Mashallah Wall Hanging.",
    price: 1200,
    imgpx: require("./assets/250 x 250/10.jpg"),
    img: require("./assets/Mashallah 2 Card & Images/Card Mashallah.jpg"),
    img2: require("./assets/Mashallah 2 Card & Images/1.jpg"),
    img3: require("./assets/Mashallah 2 Card & Images/2.jpg"),
    img4: require("./assets/Mashallah 2 Card & Images/3.jpg"),
    img5: require("./assets/Mashallah 2 Card & Images/4.jpg"),
    Format1: "Format:",
    Format: "Wall Hanging",
    Material1: "Material:",
    Material: "Wooden Ply",
    Color1: 'Color:',
    Color: 'Handart Paint',
    Brand1: "Brand:",
    Brand: "Art Stylo",
    Category1: "Category:",
    Category: "Islamic",
    SKU1: "SKU:",
    SKU: 'AS-ISL0003',
  },
  {
    id: 11,
    title_one: "Islamic",
    title: "Mashallah Islamic Wall Art.",
    price: 1500,
    imgpx: require("./assets/250 x 250/9.jpg"),
    img: require("./assets/Mashallah Card & Images/Card Mashallah.jpg"),
    img2: require("./assets/Mashallah Card & Images/1.jpg"),
    img3: require("./assets/Mashallah Card & Images/2.jpg"),
    img4: require("./assets/Mashallah Card & Images/3.jpg"),
    img5: require("./assets/Mashallah Card & Images/4.jpg"),
    Format1: "Format:",
    Format: "Wall Hanging",
    Material1: "Material:",
    Material: "Wooden Ply",
    Color1: 'Color:',
    Color: 'Handart Paint',
    Brand1: "Brand:",
    Brand: "Art Stylo",
    Category1: "Category:",
    Category: "Islamic",
    SKU1: "SKU:",
    SKU: 'AS-ISL0004',
  },
];
